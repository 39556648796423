import React from "react";
import Slider from "react-slick";
import { GatsbyImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HotSlider = ({ slides }) => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 4000,
		fade: true,
	};
	return (
		<div className="mb-6">
			<Slider {...settings}>
				{slides.map((slide) => {
					return (
						<div key={slide.id}>
							<GatsbyImage
								className="rounded-lg transform"
								image={slide.localFile.childImageSharp.gatsbyImageData}
								alt={slide.name.split("-").join(" ").split(".")[0]}
								// alt={slide.alternativeText}
								loading="eager"
							/>
						</div>
					);
				})}
			</Slider>
		</div>
	);
};

export default HotSlider;
