import React from "react";
import Slider from "react-slick";
import Image from "~/components/image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HighlightSlider = ({ highlights }) => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 4000,
		fade: true,
	};
	return (
		<div>
			<Slider {...settings}>
				{highlights.length > 0 &&
					highlights.map((light) => {
						return (
							<div key={light.id}>
								<div className="flex flex-col md:flex-row  md:justify-center md:items-center">
									<div className="md:w-2/3 md:mr-16 my-4">
										<Image
											className="rounded-md transform"
											image={light.image}
											alt="Highlight Image"
										/>
									</div>
									<div className="md:w-1/3 my-4">
										<h4 className="capitalize">{light.heading}</h4>
										<span>{light.desc}</span>
									</div>
								</div>
							</div>
						);
					})}
			</Slider>
		</div>
	);
};

export default HighlightSlider;
